.react-image-modal-order-analysis {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.createDateRangePicker.ant-calendar-picker-input.ant-input:hover {
  border-color: #7635dc !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
  box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
}

.createDateRangePicker:hover {
  border-color: #7635dc !important;
}

.createDateRangePicker.ant-picker-focused {
  border-color: #7635dc !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(90, 49, 139, 0.342) !important;
  box-shadow: 0 0 0 2px rgba(90, 49, 139, 0.342) !important;
}

.createDateRangePicker .ant-picker-active-bar {
  background-color: #7635dc !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #7635dc !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #7635dc !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #7635dc !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #e6ffe8 !important;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #7635dc !important;
  border-color: #7635dc !important;
}
.practice{
  z-index: 5;
}
.button{
  position: absolute;
  float: right;
}
.button1{
  position: absolute;
  float: left;
  right: 90px;
}
.parentbtn{
  padding-top: 10px;
}
.css-19r6kue-MuiContainer-root{
  max-width: 100% !important;
}

.css-1ekb41w{
  max-width: 100% !important;
}